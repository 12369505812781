import React from "react";

const Spinner = ({ message }) => {
  return (
    <div className="sk-chase__content">
      <>
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </>
      {message && <p className="sk-chase__message">{message}</p>}
    </div>
  );
};

export default Spinner;
