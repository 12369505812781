import { useEffect } from "react";

const AdGlare = ({ zoneId, zoneIdMobileOverride, uniqueId }) => {
  uniqueId = uniqueId ?? zoneId;

  useEffect(() => {
    const isMobile = () => window && window.innerWidth < 768;
    const currentZoneId =
      isMobile() && parseInt(zoneIdMobileOverride) > 0
        ? zoneIdMobileOverride
        : zoneId;
    const adContainer = document.getElementById(`adglare-${uniqueId}`);

    let adGlareUrl = `//engine.lto.de/?${currentZoneId}`;

    // remove dynamically added contents of unused ads
    const innerContents = document.querySelectorAll(
      `.adglare-dynamic-${uniqueId}`
    );
    if (innerContents.length > 0) {
      innerContents.forEach((element) => element.remove());
    }

    // remove dynamically added script include
    const oldScriptTag = document.querySelector(`#adglare-script-${uniqueId}`);
    if (oldScriptTag) {
      oldScriptTag.remove();
    }

    // hide label (unhide if ad was initialized)
    adContainer.querySelector(".adglare-label").classList.add("hide");

    const span = document.createElement("span");
    span.id = `zone${currentZoneId}`;
    span.classList = `adglare-dynamic-${uniqueId} ad-element`;

    // mutation observer to unhide ad label if ad was loaded
    const adMutationCallback = function (mutationsList) {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i];
        if (mutation.type === "childList") {
          if (span.querySelector("iframe")) {
            adContainer
              .querySelector(".adglare-label")
              .classList.remove("hide");
          }
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(adMutationCallback);

    const config = { childList: true, subtree: true };
    // Start observing the target node for configured mutations
    observer.observe(span, config);

    adContainer.appendChild(span);

    const s = document.createElement("script");
    s.id = `adglare-script-${uniqueId}`;
    s.async = true;
    s.setAttribute("data-cfasync", false);
    adGlareUrl += `&cb=${Date.now()}${Math.random()}`;
    s.src = adGlareUrl;
    const n = document.getElementsByTagName("script")[0];
    n.parentNode.insertBefore(s, n);
  }, [zoneId, zoneIdMobileOverride]);

  if (!zoneId || parseInt(zoneId) === 0) return null;

  return (
    <div className="ad">
      <div id={`adglare-${uniqueId}`} className="ad-element-container">
        <span className="adglare-label hide">Anzeige</span>
      </div>
    </div>
  );
};

export default AdGlare;
