import Components from "../components";
import ContentLibs from "./ContentLibs";
import PageContentsGetter from "./PageContentsGetter";
import PageContextProvider from "../context/PageContext";

const renderTwoColumnContents = (
  mainContents,
  rightContents,
  page,
  additionalContextData = {}
) => {
  const layout = page.layout ?? "";
  let mainColumnSizeClasses = "";
  switch (layout) {
    case 1:
      mainColumnSizeClasses = "large-7 large-offset-2";
      break;
    default:
      mainColumnSizeClasses = "large-9";
  }

  return (
    <PageContextProvider page={page} additionalData={additionalContextData}>
      <div className="grid-x grid-margin-x">
        <div className={`cell ${mainColumnSizeClasses} ce-elements`}>
          {mainContents &&
            mainContents.map((contentItem, i) => Components(contentItem, i))}
        </div>
        <div className="cell large-3 ce-elements">
          {rightContents &&
            rightContents.map((contentItem, i) => Components(contentItem, i))}
        </div>
      </div>
    </PageContextProvider>
  );
};

const renderOneColumnContents = (
  mainContents,
  page,
  additionalContextData = {}
) => {
  const layout = page.layout ?? "";
  let mainColumnSizeClasses = "";
  switch (layout) {
    case 1:
      mainColumnSizeClasses = "large-10 large-offset-2";
      break;
    default:
      mainColumnSizeClasses = "large-12";
  }

  return (
    <div className="grid-x">
      <div className={`cell ${mainColumnSizeClasses} ce-elements`}>
        {mainContents &&
          mainContents.map((contentItem, i) => Components(contentItem, i))}
      </div>
    </div>
  );
};

const renderPage = (page, pageContents, additionalContextData) => {
  const mainColumnContents = PageContentsGetter.getMainColumn(pageContents);
  const rightColumnContents = PageContentsGetter.getRightColumn(pageContents);

  /**
   * API returns in case of only one element on page a object instead of an array with only one element.
   * Therefore we convert the content elements to an array.
   */
  const mainColumnContentElements =
    ContentLibs.unifyContentArray(mainColumnContents);
  const rightColumnContentElements =
    ContentLibs.unifyContentArray(rightColumnContents);

  let pageContent = "";
  switch (page.backendLayout) {
    case "pagets__contentWithRightColumn":
      // two column layout (widget column beside)
      pageContent = renderTwoColumnContents(
        mainColumnContentElements,
        rightColumnContentElements,
        page,
        additionalContextData
      );
      break;
    default:
      pageContent = renderOneColumnContents(
        mainColumnContentElements,
        page,
        additionalContextData
      );
  }
  return pageContent;
};

export default {
  renderTwoColumnContents,
  renderOneColumnContents,
  renderPage,
};
