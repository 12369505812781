import React from 'react';
import { getLayout as getMainLayout } from './MainLayout';
import PageHeader from '../modules/cms_content/components/global/PageHeader/PageHeader';
import HighlightColor from '../components/HighlightColor';
import PageContentsGetter from '../modules/cms_content/libs/PageContentsGetter';
import AdGlare from '../components/AdGlare/AdGlare';

const CMSPageLayout = ({ page, pageContents, children }) => (
  <>
    {page && (
    <>
      <PageHeader
        headerImage={page.headerImage && page.headerImage.length > 0 ? page.headerImage[0] : ''}
        logo={page.imageBesideOnPageNav?.[0]}
        showOnPageNav={page.showOnPageNav}
        hideBreadcrumb={page.hideBreadcrumb}
        rootline={page.rootline}
        contents={PageContentsGetter.getMainColumn(pageContents)}
      />
      {parseInt(page.adglareBillboard) !== 0 && <AdGlare zoneId={page.adglareBillboard} zoneIdMobileOverride={page.adglareMobile} uniqueId="page-billboard" />}

      <HighlightColor color={page.highlightColor} />
    </>
    )}
    <div className="page-content__content-elements">
      {children}
    </div>
  </>
);

export const getLayout = (page, pageProps) => getMainLayout(<CMSPageLayout {...pageProps}>{page}</CMSPageLayout>, pageProps);

export default CMSPageLayout;
