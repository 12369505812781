import React from "react";

const HighlightColor = ({ color }) => {
  if (!color) {
    color = "#df1051";
  }

  const padZero = (str, len) => {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  };

  /**
   * Invert color
   *
   * - Default to invert color: invertColor(hex)
   * - Invert to black or white: invertColor(hex, true)
   * - Invert to customWhite or customDark: invertColor(hex, true, '#fff', '#000')
   * - Invert to customWhite or customDark with specific breakpoint: invertColor(hex, true, '#fff', '#000', 80)
   *
   * @param hex color value
   * @param bw invert to black or white
   * @param customWhite set custom white color
   * @param customDark set custom dark color
   * @param breakpoint set breakpoint to switch between dark and bright version
   * @returns {string|string}
   */
  const invertColor = (
    hex,
    bw = false,
    customWhite = "#ffffff",
    customDark = "#000000",
    breakpoint = 186
  ) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > breakpoint
        ? customDark
        : customWhite;
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  };

  const invertedHighlightColorToBlackOrWhite = invertColor(color, true);
  const invertedHighlightColorForBrightBackground = invertColor(
    color,
    true,
    color,
    "#000",
    220
  );
  const invertedInvertedHighlightColorForBrightBackground = invertColor(
    invertedHighlightColorForBrightBackground,
    true
  );
  const invertedHighlightColorForDarkBackground = invertColor(
    color,
    true,
    "#fff",
    color,
    120
  );

  {
    /* Global styles: https://github.com/vercel/styled-jsx#global-styles */
  }
  return (
    <style jsx global>{`
      .ce--layout-bg-highlight-color {
        background-color: ${color} !important;
      }
      .ce--layout-bg-highlight-color .highlight-color {
        color: ${invertColor(color, true)} !important;
      }
      .highlight-color {
        color: ${color} !important;
      }
      .highlight-invert-bw-color {
        color: ${invertColor(color, true)} !important;
      }
      .highlight-color-background {
        background-color: ${color} !important;
      }
      .highlight-color-border {
        border-color: ${color} !important;
      }
      .highlight-color-border-hover:hover {
        border-color: ${color} !important;
      }
      :root {
        --highlight-color: ${color};
        --highlight-invert-for-bright-bg-color: ${invertedHighlightColorForBrightBackground};
        --highlight-invert-invert-for-bright-bg-color: ${invertedInvertedHighlightColorForBrightBackground};
        --highlight-invert-for-dark-bg-color: ${invertedHighlightColorForDarkBackground};
        --highlight-invert-bw-color: ${invertedHighlightColorToBlackOrWhite};
      }
    `}</style>
  );
};

export default HighlightColor;
